import React, { useRef } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { motion } from 'framer-motion'

import PageTitle from '../PageTitle/PageTitle'
import {
    Box,
    Flex,
    Heading,
    Container,
    Text,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react'

import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../../utils/linkResolver'

export function TeamMember(props) {

  const { isOpen, onOpen, onClose } = useDisclosure();
  const finalRef = useRef(null)

 return (
        <>
          <Box w={{base: '100%', md: '33%', lg: '25%'}} p="4" as={motion.div} onMouseDown={onOpen} ref={finalRef} >
          <Flex flexDirection="column" justifyContent="space-around" h="100%" bg="brand.600" p="2">            
              <Box w="100%">
                  <GatsbyImage image={getImage(props.playerImage)} alt={props.alt} width="100%"/>
                  {/* <StaticImage src="../../images/player.jpg" alt="sample player" /> */}
              </Box>
              <Box position="relative">
                  <Text color="white" fontSize="1.5rem" marginBottom="0" fontWeight="700">
                      {props.playerName}
                  </Text>
                  <Text color="white" fontSize="1.5rem" marginBottom="0">
                      {props.playerPosition}
                  </Text>
              </Box>
          </Flex>
      </Box>
  
  <Modal isOpen={isOpen} onClose={onClose} isCentered>
  <ModalOverlay />
  <ModalContent>
  <ModalHeader>
      {props.playerName}
  </ModalHeader>
  <ModalCloseButton />
  <ModalBody>
      <Text fontWeight="700">
          {props.playerPosition}
      </Text>
      <RichText render={props.bio.richText} linkResolver={linkResolver} />
  </ModalBody>
  </ModalContent>
  </Modal>
        </>

    )
}

const TeamRoster = () => {
    const data = useStaticQuery(graphql`
    {
      allPrismicStaffAndBoard {
        edges {
          node {
            data {
              staff {
                position {
                  text
                }
                photo {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
                    }
                  }
                }
                organization
                name {
                  text
                }
                bio {
                  richText
                }
              }
            }
          }
        }
      }
    }
  `)

  const staffBoard = data.allPrismicStaffAndBoard.edges[0].node.data.staff;

    return (
        <Box bg="black" h="100%">
            <Container maxW="container.xl">
          
            <PageTitle title="Staff &amp; Board" color="white"/>
            
           
            <Box pb="20">
              <Heading as="h2" color="white" textAlign="center" mb="8" fontFamily="lato">Snow King Sports &amp; Event Center Staff</Heading>
                <Flex w="100%" flexWrap="wrap" justifyContent="space-between">
                    {staffBoard.map((person) => (
                        person.organization === 'Staff' ? (
                          <TeamMember
                              playerImage={person.photo.localFile}
                              playerName={person.name.text}
                              playerPosition={person.position.text}
                              bio={person.bio}
                          />
                        ) : null
                    ))
                    }
                </Flex>
            </Box>
            
            <Box pb="20">
              <Heading as="h2" color="white" textAlign="center" mb="8" fontFamily="lato">Snow King Sports &amp; Event Center Board of Directors</Heading>
                <Flex w="100%" flexWrap="wrap" justifyContent="space-between">
                    {staffBoard.map((person) => (
                        person.organization === 'Board' ? (
                          <TeamMember
                              bio={person.bio} 
                              playerImage={person.photo.localFile}
                              playerName={person.name.text}
                              playerPosition={person.position.text}
                          />
                        ) : null
                    ))
                    }
                </Flex>
            </Box>
            </Container>
        </Box>
    )
}

export default TeamRoster;